define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/gabbys-website-featured-resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QvRhfMO6",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Featured Resource\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@prefix\",\"@label\",\"@value\",\"@required\"],[\"#\",\"Anchor ID\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"anchor_id\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Tag (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"tag\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\",\"@required\"],[\"Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"title\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\",\"@rows\",\"@required\"],[\"Description\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"description\"]],\"5\",\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"dreamworks/resource-selector-form\",[],[[\"@defaultType\",\"@selectedType\",\"@selectedId\",\"@label\",\"@showResourceTypeOptions\",\"@languageId\",\"@onChange\"],[\"gabbys_website_default\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"resource_type\"]],[27,[26,0,\"AppendSingleId\"],[\"data\",\"resource_id\"]],\"Resource\",true,[27,[26,1,\"AppendSingleId\"],[]],[31,876,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,884,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"resource_id\"]]],null]],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"languageId\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/gabbys-website-featured-resource.hbs"
    }
  });

  _exports.default = _default;
});