define("universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-character-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QPVzIV+2",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Character Grid\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@prefix\",\"@label\",\"@value\",\"@required\"],[\"#\",\"Anchor ID\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"anchor_id\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"resources-container\",null],[10],[1,1,0,0,\"\\n            \"],[7,\"dreamworks/resource-list\",[],[[\"@pageType\",\"@resourceIds\",\"@selectedResourceTypeOption\",\"@showResourceTypesRadioOptions\",\"@canAdd\",\"@languageId\"],[\"gabbys_website_character\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"character_ids\"]],[27,[26,1,\"AppendSingleId\"],[]],true,true,[27,[26,2,\"AppendSingleId\"],[\"language_id\"]]]],null],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[12,\"style\",\"margin-top:1.25rem;\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"CTA Button Text (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"cta_button_text\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"characterResourceTypeOption\",\"pageData\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-character-grid.hbs"
    }
  });

  _exports.default = _default;
});