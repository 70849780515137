define("universal-cms/templates/universal-studios/clear-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F8uC9nk7",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    Clear Cache\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"form\",false],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"clearCache\"],[[\"on\"],[\"submit\"]]],[10],[1,1,0,0,\"\\n    \"],[9,\"button\",true],[12,\"class\",\"btn btn-primary\",null],[12,\"type\",\"submit\",null],[10],[1,1,0,0,\"Clear Cache\"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/universal-studios/clear-cache.hbs"
    }
  });

  _exports.default = _default;
});