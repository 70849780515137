define("universal-cms/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    isHeaderFixed: true,
    cmsTitle: Ember.computed('cmsTitle', function () {
      if (this.auth.decoded.scopes.mappings.DreamWorks && !this.auth.decoded.scopes.mappings.Website) {
        return 'DreamWorks CMS';
      } else {
        return 'Universal Pictures CMS';
      }
    }),
    moviesNavItems: Ember.computed('this.serviceResource.url', 'routing.currentRouteName', function () {
      const url = this.serviceResource.url;

      if (!this.auth.decoded.scopes[url]) {
        return [];
      }

      let group = this.auth.decoded.scopes[url]['group'];
      let items = [{
        text: 'Movies',
        route: 'movies',
        active: this.routing.currentRouteName.startsWith('movies.list') || this.routing.currentRouteName.startsWith('movies.edit')
      }];

      if (group === 'admin') {
        items.push({
          text: 'Option Types',
          route: 'optionTypes',
          active: this.routing.currentRouteName.startsWith('optionTypes.list') || this.routing.currentRouteName.startsWith('optionTypes.edit')
        });
        items.push({
          text: 'Option Groups',
          route: 'optionGroups',
          active: this.routing.currentRouteName.startsWith('optionGroups.list') || this.routing.currentRouteName.startsWith('optionGroups.edit')
        });
        items.push({
          text: 'Asset Types',
          route: 'assetTypes',
          active: this.routing.currentRouteName.startsWith('assetTypes.list') || this.routing.currentRouteName.startsWith('assetTypes.edit')
        });
        items.push({
          text: 'Asset Groups',
          route: 'assetGroups',
          active: this.routing.currentRouteName.startsWith('assetGroups.list') || this.routing.currentRouteName.startsWith('assetGroups.edit')
        });
      }

      if (group === 'admin' || group === 'universal') {
        items.push({
          text: 'Genres',
          route: 'genres.list',
          active: this.routing.currentRouteName.startsWith('genres.list') || this.routing.currentRouteName.startsWith('genres.edit')
        });
      }

      items.push({
        text: 'People',
        route: 'people',
        active: this.routing.currentRouteName.startsWith('people.list') || this.routing.currentRouteName.startsWith('people.edit')
      });
      items.push({
        text: 'Roles',
        route: 'roles',
        active: this.routing.currentRouteName.startsWith('roles.list') || this.routing.currentRouteName.startsWith('roles.edit')
      });
      items.push({
        text: 'Retailers',
        route: 'retailers',
        active: this.routing.currentRouteName.startsWith('retailers.list') || this.routing.currentRouteName.startsWith('retailers.edit')
      });
      return items;
    }),
    websiteNavItems: Ember.computed('serviceResource.url', 'routing.{currentRouteName,router.currentURL}', function () {
      const url = this.serviceResource.url;

      if (!this.auth.decoded.scopes[url]) {
        return [];
      }

      let group = this.auth.decoded.scopes[url]['group'];
      let navItems = [];

      if (group !== 'press') {
        navItems.push({
          text: 'Featured Movies',
          route: 'website.resources',
          resources: 'featured-movies',
          active: this.routing.router.currentURL === '/website/featured-movies'
        });
        navItems.push({
          text: 'Featured Videos',
          route: 'website.resources',
          resources: 'featured-videos',
          active: this.routing.router.currentURL === '/website/featured-videos'
        });
        navItems.push({
          text: 'Playlists',
          route: 'website.resources',
          resources: 'playlists',
          active: this.routing.router.currentURL === '/website/playlists'
        });
      }

      navItems.push({
        text: 'News & Press',
        route: 'website.resources',
        resources: 'articles',
        active: this.routing.router.currentURL.startsWith('/website/articles')
      });

      if (group !== 'press') {
        navItems.push({
          text: 'Executives',
          route: 'website.resources',
          resources: 'executives',
          active: this.routing.router.currentURL.startsWith('/website/executives')
        });
      }

      if (group !== 'press') {
        navItems.push({
          text: 'Universal Classics',
          route: 'website.resources',
          resources: 'classics',
          active: this.routing.router.currentURL.startsWith('/website/classics')
        });
        navItems.push({
          text: 'Movie Promos',
          route: 'website.moviePromos',
          active: this.routing.router.currentURL.startsWith('/website/movie-promos')
        });
        navItems.push({
          text: 'Home Page Promos',
          route: 'website.homePagePromos',
          active: this.routing.router.currentURL.startsWith('/website/home-page-promos')
        });
      }

      if (group !== 'press' && group !== 'universal') {
        navItems.push({
          text: 'Spot Types',
          route: 'website.resources',
          resources: 'spot-types',
          active: this.routing.router.currentURL === '/website/spot-types'
        });
      }

      if (group !== 'press') {
        navItems.push({
          text: 'Social Accounts',
          route: 'website.resources',
          resources: 'social-accounts',
          active: this.routing.router.currentURL === '/website/social-accounts'
        });
        navItems.push({
          text: 'Custom Pages',
          route: 'website.resources',
          resources: 'custom-pages',
          active: this.routing.router.currentURL.startsWith('/website/custom-pages')
        });
        navItems.push({
          text: 'Preview',
          route: 'website.preview',
          active: this.routing.router.currentURL === '/website/preview'
        });
        navItems.push({
          text: 'SEO',
          route: 'website.seo',
          active: this.routing.router.currentURL.startsWith('/website/seo')
        });
      }

      return navItems;
    }),
    brandNavItems: Ember.computed('serviceResource.url', 'routing.currentRouteName', function () {
      const url = this.serviceResource.url;

      if (!this.auth.decoded.scopes[url]) {
        return [];
      }

      const group = this.auth.decoded.scopes[url]['group'];
      let navItems = [];

      if (group === 'admin' || group === 'brand') {
        navItems.push({
          text: 'General',
          route: 'universalBrand.general.list',
          active: this.routing.currentRouteName.startsWith('universalBrand.general')
        });
        navItems.push({
          text: 'Pages',
          route: 'universalBrand.pages.list',
          active: this.routing.currentRouteName.startsWith('universalBrand.pages')
        });
        navItems.push({
          text: 'News',
          route: 'universalBrand.news.list',
          active: this.routing.currentRouteName.startsWith('universalBrand.news')
        });
        navItems.push({
          text: 'Businesses',
          route: 'universalBrand.businesses.list',
          active: this.routing.currentRouteName.startsWith('universalBrand.businesses')
        });
        navItems.push({
          text: 'Projects',
          route: 'universalBrand.projects.list',
          active: this.routing.currentRouteName.startsWith('universalBrand.projects')
        });
        navItems.push({
          text: 'Categories',
          route: 'universalBrand.categories.list',
          active: this.routing.currentRouteName.startsWith('universalBrand.categories')
        });
      }

      return navItems;
    }),
    studiosNavItems: Ember.computed('universalStudiosService.url', 'routing.currentRouteName', function () {
      const url = this.universalStudiosService.url;

      if (!this.auth.decoded.scopes[url]) {
        return [];
      }

      const group = this.auth.decoded.scopes[url]['group'];

      if (group !== 'admin') {
        return [];
      }

      let navItems = [];
      navItems.push({
        text: 'Movies',
        route: 'universalStudios.movies.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.movies')
      });
      navItems.push({
        text: 'News',
        route: 'universalStudios.news.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.news')
      });
      navItems.push({
        text: 'Playlists',
        route: 'universalStudios.playlists.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.playlists')
      });
      navItems.push({
        text: 'Videos',
        route: 'universalStudios.videos.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.videos')
      });
      navItems.push({
        text: 'Features',
        route: 'universalStudios.features.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.features')
      });
      navItems.push({
        text: 'Slider',
        route: 'universalStudios.slider.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.slider')
      });
      navItems.push({
        text: 'Connect',
        route: 'universalStudios.connect.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.connect')
      });
      navItems.push({
        text: 'Banners',
        route: 'universalStudios.banners.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.banners')
      });
      navItems.push({
        text: 'Banner Headers',
        route: 'universalStudios.bannerHeaders.list',
        active: this.routing.currentRouteName.startsWith('universalStudios.bannerHeaders')
      });
      navItems.push({
        text: 'Clear Cache',
        route: 'universalStudios.clear-cache',
        active: this.routing.currentRouteName === 'universalStudios.clear-cache'
      });
      return navItems;
    }),
    dreamworksNavItems: Ember.computed('routing.currentRouteName', function () {
      const url = this.dreamworksService.getUrl();

      if (!this.auth.decoded.scopes[url]) {
        return [];
      }

      const group = this.auth.decoded.scopes[url]['group'];

      if (group !== 'dreamworks') {
        return [];
      }

      let navItems = [];
      navItems.push({
        text: 'Pages',
        route: 'dreamworks.pages.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.pages')
      });
      navItems.push({
        text: 'Franchises',
        route: 'dreamworks.franchises.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.franchises')
      });
      navItems.push({
        text: 'Movies',
        route: 'dreamworks.movies.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.movies')
      });
      navItems.push({
        text: 'TV Shows',
        route: 'dreamworks.tv-shows.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.tv-shows')
      });
      navItems.push({
        text: 'Games',
        route: 'dreamworks.games.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.games')
      });
      navItems.push({
        text: 'Events',
        route: 'dreamworks.events.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.events')
      });
      navItems.push({
        text: 'Leadership',
        route: 'dreamworks.leadership.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.leadership')
      });
      navItems.push({
        text: 'Retailers',
        route: 'dreamworks.retailers.list',
        active: this.routing.currentRouteName.startsWith('dreamworks.retailers')
      });
      navItems.push({
        text: 'Settings',
        route: 'dreamworks.settings',
        active: this.routing.currentRouteName === 'dreamworks.settings'
      });
      navItems.push({
        text: 'Navigation',
        route: 'dreamworks.navigation',
        active: this.routing.currentRouteName === 'dreamworks.navigation'
      });
      navItems.push({
        text: 'Clear Cache',
        route: 'dreamworks.clear-cache',
        active: this.routing.currentRouteName === 'dreamworks.clear-cache'
      });
      return navItems;
    }),
    gabbyNavItems: Ember.computed('routing.currentRouteName', function () {
      const url = this.dreamworksService.getGabbysWebsiteUrl();

      if (!this.auth.decoded.scopes[url]) {
        return [];
      }

      const group = this.auth.decoded.scopes[url]['group'];

      if (group !== 'gabbys-website') {
        return [];
      }

      const navItems = [];
      navItems.push({
        text: 'Pages',
        route: 'gabbys-website.pages.list',
        active: this.routing.currentRouteName.startsWith('gabbys-website.pages')
      });
      navItems.push({
        text: 'Characters',
        route: 'gabbys-website.gabbys-website-characters.list',
        active: this.routing.currentRouteName.startsWith('gabbys-website.gabbys-website-characters')
      });
      navItems.push({
        text: 'Events',
        route: 'gabbys-website.gabbys-website-events.list',
        active: this.routing.currentRouteName.startsWith('gabbys-website.gabbys-website-events')
      });
      navItems.push({
        text: 'Episodes',
        route: 'gabbys-website.gabbys-website-episodes.list',
        active: this.routing.currentRouteName.startsWith('gabbys-website.gabbys-website-episodes')
      });
      navItems.push({
        text: 'Partners',
        route: 'gabbys-website.gabbys-website-partners.list',
        active: this.routing.currentRouteName.startsWith('gabbys-website.gabbys-website-partners')
      });
      navItems.push({
        text: 'Navigation',
        route: 'gabbys-website.navigation',
        active: this.routing.currentRouteName.startsWith('gabbys-website.navigation')
      });
      navItems.push({
        text: 'Settings',
        route: 'gabbys-website.settings',
        active: this.routing.currentRouteName.startsWith('gabbys-website.settings')
      });
      navItems.push({
        text: 'Clear Cache',
        route: 'gabbys-website.clear-cache',
        active: this.routing.currentRouteName === 'gabbys-website.clear-cache'
      });
      return navItems;
    }),
    dashboardNavItems: Ember.computed('routing.currentRouteName', function () {
      let navItems = [];

      if (this.auth.url) {
        let group = this.auth.decoded.scopes[this.auth.url]['group'];
        navItems.push({
          text: 'Users',
          route: 'dashboard.users',
          active: this.routing.currentRouteName.startsWith('dashboard.users')
        });

        if (group === 'admin') {
          navItems.push({
            text: 'User Groups',
            route: 'dashboard.userGroups',
            active: this.routing.currentRouteName.startsWith('dashboard.userGroups')
          });
        }

        navItems.push({
          text: 'Documentation',
          route: 'dashboard.documentation',
          active: this.routing.currentRouteName.startsWith('dashboard.documentation')
        });
      }

      return navItems;
    }),
    uwatchNavItems: Ember.computed('uwatchService.url', 'routing.currentRouteName', function () {
      const url = this.uwatchService.getUrl();

      if (!this.auth.decoded.scopes[url]) {
        return [];
      }

      let navItems = [];
      navItems.push({
        text: 'Featured Movies',
        route: 'uwatch.resources',
        resources: 'featured-movies',
        active: this.routing.currentRouteName.startsWith('/uwatch/featured-movies')
      });
      navItems.push({
        text: 'Coming Soon',
        route: 'uwatch.resources',
        resources: 'coming-soon',
        active: this.routing.currentRouteName.startsWith('/uwatch/coming-soon')
      });
      navItems.push({
        text: 'Own It',
        route: 'uwatch.resources',
        resources: 'own-it',
        active: this.routing.currentRouteName.startsWith('/uwatch/own-it')
      });
      navItems.push({
        text: 'FAQs',
        route: 'uwatch.resources',
        resources: 'faqs',
        active: this.routing.currentRouteName.startsWith('/uwatch/faqs')
      });
      navItems.push({
        text: 'About',
        route: 'uwatch.about',
        active: this.routing.currentRouteName.startsWith('/uwatch/about')
      });
      return navItems;
    }),
    pageContainerClass: Ember.computed(function () {
      return `page-container page-sidebar-fixed${this.isHeaderFixed ? ' page-header-fixed' : ''}`;
    }),
    sidebarToggled: false,
    actions: {
      toggleSidebar() {
        this.set('sidebarToggled', !this.sidebarToggled);
        this.set('pageContainerClass', 'page-container page-sidebar-fixed page-header-fixed' + (this.sidebarToggled === true ? ' page-sidebar-toggled' : ''));
      },

      deleteCache() {
        if (confirm('Are you sure you wish to delete the application cache? Pages will be slow until they can be recached.')) {
          this.serviceResource.deleteResource('website', 'cache').then(data => {
            this.notificationService.show('Cache Deleted', 'Application cache deleted succesfuly');
          });
        }
      }

    },
    isMoviesPage: Ember.computed('routing.currentRouteName', function () {
      return this.routing.currentRouteName.startsWith('movies') || this.routing.currentRouteName.startsWith('optionTypes') || this.routing.currentRouteName.startsWith('optionGroups') || this.routing.currentRouteName.startsWith('assetTypes') || this.routing.currentRouteName.startsWith('assetGroups') || this.routing.currentRouteName.startsWith('genres') || this.routing.currentRouteName.startsWith('people') || this.routing.currentRouteName.startsWith('roles') || this.routing.currentRouteName.startsWith('retailers');
    }),
    isUniversalPicturesPage: Ember.computed('routing.currentRouteName', function () {
      return this.routing.currentRouteName.startsWith('website.');
    }),
    isUniversalStudiosPage: Ember.computed('routing.currentRouteName', function () {
      return this.routing.currentRouteName.startsWith('universalStudios');
    }),
    isUniversalBrandPage: Ember.computed('routing.currentRouteName', function () {
      return this.routing.currentRouteName.startsWith('universalBrand');
    }),
    isUWatchPage: Ember.computed('routing.currentRouteName', function () {
      return this.routing.currentRouteName.startsWith('uwatch');
    }),
    isDreamWorksPage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      return this.routing.currentRouteName.startsWith('dreamworks') || Object.keys(serviceMappings).length === 1 && serviceMappings.DreamWorks;
    }),
    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');

      if (this.routing.currentRouteName === 'index' && serviceMappingsKeys.includes('GabbysWebsite')) {
        return true;
      }

      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),
    isDashboardPage: Ember.computed('routing.currentRouteName', function () {
      return this.routing.currentRouteName.startsWith('dashboard');
    })
  });

  _exports.default = _default;
});