define("universal-cms/components/gabbys-website/navigation-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['navigation-manager'],

    didUpdateAttrs() {
      this._super(...arguments);

      this.notifyItemsUpdated();
    },

    notifyItemsUpdated() {
      if (typeof this.onItemsUpdated === 'function') {
        this.onItemsUpdated(this.items);
      }
    },

    actions: {
      addItem() {
        this.get('items').pushObject({
          text: '',
          url: '',
          icon: '',
          external: false,
          subItems: Ember.A(),
          location: this.location
        });
        this.notifyItemsUpdated();
      },

      removeItem(item) {
        if (!confirm('Are you sure you want to remove this item?')) {
          return;
        }

        this.get('items').removeObject(item);
        this.notifyItemsUpdated();
      },

      addSubItem(item) {
        if (!item.subItems) {
          Ember.set(item, 'subItems', Ember.A());
        }

        item.subItems.pushObject({
          text: '',
          url: ''
        });
        this.notifyItemsUpdated();
      },

      removeSubItem(item, subItem) {
        if (!confirm('Are you sure you want to remove this item?')) {
          return;
        }

        item.subItems.removeObject(subItem);
        this.notifyItemsUpdated();
      },

      moveItemUp(index) {
        if (index > 0) {
          const items = this.get('items');
          items.splice(index - 1, 0, items.splice(index, 1)[0]);
          this.notifyPropertyChange('items');
          this.notifyItemsUpdated();
        }
      },

      moveItemDown(index) {
        const items = this.get('items');

        if (index < items.length - 1) {
          items.splice(index + 1, 0, items.splice(index, 1)[0]);
          this.notifyPropertyChange('items');
          this.notifyItemsUpdated();
        }
      },

      moveSubItemUp(item, subIndex) {
        if (subIndex > 0) {
          const subItems = item.subItems;
          subItems.splice(subIndex - 1, 0, subItems.splice(subIndex, 1)[0]);
          this.notifyPropertyChange('items');
          this.notifyItemsUpdated();
        }
      },

      moveSubItemDown(item, subIndex) {
        const subItems = item.subItems;

        if (subIndex < subItems.length - 1) {
          subItems.splice(subIndex + 1, 0, subItems.splice(subIndex, 1)[0]);
          this.notifyPropertyChange('items');
          this.notifyItemsUpdated();
        }
      }

    }
  });

  _exports.default = _default;
});