define("universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-related-resources-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gzi1eodR",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Related Resources Grid\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@prefix\",\"@label\",\"@value\",\"@required\"],[\"#\",\"Anchor ID\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"anchor_id\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\",\"@required\"],[\"Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"title\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"pageComponent\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-related-resources-grid.hbs"
    }
  });

  _exports.default = _default;
});