define("universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-standard-diptych-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vv0KFoIu",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Standard Diptych Grid\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@prefix\",\"@label\",\"@value\",\"@required\"],[\"#\",\"Anchor ID\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"anchor_id\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Heading\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"heading\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Custom CTA\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"custom_cta\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@onItemsUpdated\"],[[27,[26,1,\"AppendSingleId\"],[]],[27,[26,0,\"AppendSingleId\"],[\"data\",\"items\"]],\"resource\",[31,499,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,507,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"items\"]]],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"resourceFormOptions\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-standard-diptych-grid.hbs"
    }
  });

  _exports.default = _default;
});