define("universal-cms/services/universal-studios", ["exports", "jquery", "universal-cms/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),

    setUrl(url) {
      this.set('url', url);
    },

    getUrl() {
      let route = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let url = this.url + '/api' + (route !== null ? (route.substring(0, 1) !== '/' ? '/' : '') + route : '');

      if (id !== null) {
        url += `/${id}`;
      }

      return url;
    },

    getOne(resource, id) {
      let url = this.getUrl(resource, id);
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += `nocache=${Math.floor(Math.random() * 99999)}`;
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'GET',
          url: url,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(Ember.Object.create(response.data));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    getAll(resource) {
      let url = this.getUrl(resource);
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += `nocache=${Math.floor(Math.random() * 99999)}`;
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'GET',
          url: url,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(Ember.A(response.data.map(item => {
            return Ember.Object.create(item);
          })));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    createOne(resource, data) {
      let withFiles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let formData;

      if (withFiles) {
        formData = new FormData();

        for (let key in data) {
          if (typeof data[key] === 'undefined') {
            continue;
          }

          formData.append(key, data[key]);
        }
      } else {
        formData = data;
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'POST',
          url: this.getUrl(resource),
          data: formData,
          contentType: withFiles ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
          processData: !withFiles,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(Ember.Object.create(response));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    updateOne(resource, id, data) {
      let withFiles = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      let formData;

      if (withFiles) {
        formData = new FormData();

        for (let key in data) {
          if (typeof data[key] === 'undefined') {
            continue;
          }

          formData.append(key, data[key]);
        }

        formData.append('_method', 'PUT');
      } else {
        formData = data;
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: withFiles ? 'POST' : 'PUT',
          url: `${this.getUrl(resource, id)}`,
          data: formData,
          contentType: withFiles ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
          processData: !withFiles,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(Ember.Object.create(response));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    deleteOne(resource, id) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'DELETE',
          url: `${this.getUrl(resource, id)}`,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(response);
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    post(url, data) {
      let withFiles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let formData;

      if (withFiles) {
        formData = new FormData();

        for (let key in data) {
          if (typeof data[key] === 'undefined') {
            continue;
          }

          formData.append(key, data[key]);
        }
      } else {
        formData = data;
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'POST',
          url: this.getUrl(url),
          data: formData,
          contentType: withFiles ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
          processData: !withFiles,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(Ember.Object.create(response));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    // get(url) {
    //     return new EmberPromise((resolve, reject) => {
    //         $.ajax({
    //             method: 'GET',
    //             url: this.getUrl(url),
    //             beforeSend: xhr => {
    //                 xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);
    //                 if (environment.BASIC_AUTH_USERNAME) {
    //                     xhr.setRequestHeader('Authorization', `Basic ${btoa(environment.BASIC_AUTH_USERNAME + ':' + environment.BASIC_AUTH_PASSWORD)}`);
    //                 }
    //             },
    //         }).then(response => {
    //             resolve(EmberObject.create(response));
    //         }, errorResponse => {
    //             reject(errorResponse);
    //         });
    //     });
    // },
    formatErrorResponse(errors) {
      if (typeof errors === 'string') {
        return errors;
      }

      let str = '';

      for (let errorIndex = 0, numErrors = errors.length; errorIndex < numErrors; errorIndex++) {
        str += errors[errorIndex];

        if (errorIndex < numErrors - 1) {
          str += '<br>';
        }
      }

      return str;
    }

  });

  _exports.default = _default;
});