define("universal-cms/router", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login', {}); // Movies

    this.route('movies', {}, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':movie_id'
      }, function () {
        this.route('genres', {});
        this.route('people', {});
        this.route('options', {});
        this.route('assets', {});
        this.route('news', {});
        this.route('retailers', {});
        this.route('critical-acclaims', {});
      });
    });
    this.route('optionTypes', {
      path: '/option-types'
    }, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':option_type_id'
      });
    });
    this.route('optionGroups', {
      path: '/option-groups'
    }, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':option_group_id'
      });
    });
    this.route('assetGroups', {
      path: '/asset-groups'
    }, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':asset_group_id'
      });
    });
    this.route('assetTypes', {
      path: '/asset-types'
    }, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':asset_type_id'
      });
    }); // Genres

    this.route('genres', {}, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':genre_id'
      });
    });
    this.route('people', {}, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':person_id'
      });
    });
    this.route('login', {});
    this.route('website', {}, function () {
      this.route('preview', {}, function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':preview_token_id'
        });
      });
      this.route('seo', {}, function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':page_id'
        });
      });
      this.route('resources', {
        path: '/:resources'
      }, function () {
        this.route('resource', {
          path: '/:resource'
        });
        this.route('list', {
          path: '/'
        });
      });
      this.route('moviePromos', {
        path: '/movie-promos'
      }, function () {
        this.route('list', {
          path: '/'
        });
        this.route('edit', {
          path: '/:movie_promo_id'
        }, function () {
          this.route('spots', {
            path: '/'
          });
          this.route('spotEdit', {
            path: '/spots/:spot_id'
          });
        });
      });
      this.route('homePagePromos', {
        path: '/home-page-promos'
      }, function () {
        this.route('list', {
          path: '/'
        });
        this.route('edit', {
          path: '/:movie_promo_id'
        }, function () {
          this.route('spots', {
            path: '/'
          });
          this.route('spotEdit', {
            path: '/spots/:spot_id'
          });
        });
      });
    });
    this.route('logout');
    this.route('roles', {}, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':role_id'
      });
    });
    this.route('retailers', {}, function () {
      this.route('list', {
        path: ''
      });
      this.route('edit', {
        path: ':retailer_id'
      });
    });
    this.route('dashboard', {}, function () {
      this.route('users', {}, function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':user_id'
        });
      });
      this.route('documentation', {}, function () {
        this.route('list', {
          path: ''
        });
        this.route('viewPage', {
          path: ':documentation_page_id'
        });
        this.route('editPage', {
          path: ':documentation_page_id/edit'
        });
      });
      this.route('userGroups', {
        path: '/user-groups'
      }, function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':user_group_id'
        });
      });
    });
    this.route('universalStudios', {
      path: '/universal-studios'
    }, function () {
      this.route('movies', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':movie_id'
        });
      });
      this.route('news', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':news_article_id'
        });
      });
      this.route('playlists', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':playlist_id'
        });
      });
      this.route('videos', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':video_id'
        });
      });
      this.route('features', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':feature_id'
        });
      });
      this.route('slider', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':slider_id'
        });
      });
      this.route('connect', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':connect_id'
        });
      });
      this.route('banners', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':banner_id'
        });
      });
      this.route('bannerHeaders', {
        path: 'banner-headers'
      }, function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':banner_header_id'
        });
      });
    });
    this.route('universalBrand', {
      path: '/universal-brand'
    }, function () {
      this.route('pages', {}, function () {
        this.route('list', {
          path: '/'
        });
        this.route('edit', {
          path: '/:page_id'
        });
        this.route('editFeaturedBanner', {
          path: '/:page_id/:page_version_id/featured-banners/:carousel_feature_item_id'
        });
        this.route('editMenuThumbnail', {
          path: '/:page_id/:page_version_id/menu-thumbnails/:menu_thumbnail_id'
        });
        this.route('editLeader', {
          path: '/:page_id/:page_version_id/leadership/:leader_id'
        });
        this.route('editContact', {
          path: '/:page_id/:page_version_id/contacts/:contact_id'
        });
        this.route('editLocation', {
          path: '/:page_id/:page_version_id/locations/:location_id'
        });
        this.route('editWorldsCharactersItem', {
          path: '/:page_id/:page_version_id/worlds-and-characters/:worlds_characters_id'
        });
      });
      this.route('news', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':news_id'
        });
      });
      this.route('businesses', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':business_id'
        });
      });
      this.route('projects', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':project_id'
        });
        this.route('editGallery', {
          path: '/:project_id/galleries/:gallery_id'
        });
      });
      this.route('general', function () {
        this.route('list');
        this.route('navigationLinks', {
          path: '/navigation-links'
        }, function () {
          this.route('edit', {
            path: ':menu_item_id'
          }, function () {});
        });
        this.route('footerLink', {
          path: '/footer-items'
        }, function () {
          this.route('edit', {
            path: ':menu_item_id'
          }, function () {});
        });
      });
      this.route('categories', function () {
        this.route('list', {
          path: ''
        });
        this.route('edit', {
          path: ':category_id'
        });
      });
    });
    this.route('uwatch', {}, function () {
      this.route('resources', {
        path: '/:resources'
      }, function () {
        this.route('resource', {
          path: '/:resource'
        });
        this.route('list', {
          path: '/'
        });
      });
      this.route('about'), {
        path: ''
      };
    });
    this.route('user', function () {
      this.route('settings', function () {});
    });
    this.route('dreamworks', function () {
      this.route('home', function () {});
      this.route('movies', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        }, function () {});
      });
      this.route('pages', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('franchises', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('resource-list');
      this.route('resource-edit');
      this.route('games', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('tv-shows', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('settings');
      this.route('leadership', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('events', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('retailers', function () {
        this.route('list');
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('navigation');
      this.route('clear-cache');
    });
    this.route('gabbys-website', function () {
      const resources = ['pages', 'gabbys-website-characters', 'gabbys-website-episodes', 'gabbys-website-events', 'gabbys-website-partners'];

      for (const resource of resources) {
        this.route(resource, function () {
          this.route('list');
          this.route('edit', {
            path: ':id'
          });
        });
      }

      this.route('navigation');
      this.route('settings');
      this.route('clear-cache');
    });
  });
  var _default = Router;
  _exports.default = _default;
});