define("universal-cms/components/gabbys-website/navigation-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['navigation-items'],
    actions: {
      addItem() {
        this.get('items').pushObject({
          text: '',
          url: '',
          icon: '',
          icon_wrapper_position: '',
          icon_position: '',
          mobile_icon: '',
          subItems: []
        });
      },

      removeItem(item) {
        this.get('items').removeObject(item);
      },

      addSubItem(item) {
        item.subItems.pushObject({
          text: '',
          url: ''
        });
      },

      removeSubItem(item, subItem) {
        item.subItems.removeObject(subItem);
      }

    }
  });

  _exports.default = _default;
});