define("universal-cms/controllers/universal-studios/clear-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    tableColumns: null,
    tableClasses: Ember.Object.create({
      'globalFilterWrapper': 'pull-right'
    }),

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'title',
        'title': 'Title'
      }, {
        'propertyName': 'description',
        'title': 'Description'
      }, {
        'propertyName': 'link',
        'title': 'Link'
      }, {
        'propertyName': 'meta',
        'title': 'Meta'
      }, {
        'propertyName': 'featured',
        'title': 'Featured'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      deleteVideo(video) {
        if (!confirm(`Are you sure you want to delete video "${video.get('title')}"?`)) {
          return;
        }

        video.set('isDeleting', true);
        this.universalStudiosService.deleteOne('videos', video.get('id')).then(() => {
          this.notificationService.show('Video deleted', `<strong>${video.get('title')}</strong> was successfully deleted.`);
          this.model.removeObject(video);
        }, () => {
          video.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete video <strong>${video.get('title')}</strong>.`);
        });
      },

      clearCache() {
        if (!confirm('Are you sure you want to clear the cache?')) {
          return;
        }

        this.universalStudiosService.post('clear-cache', {}).then(result => {
          if (result && result.success === true) {
            this.notificationService.show('Cache cleared', 'The cache was successfully cleared.');
          } else {
            this.notificationService.show('Error', result.error || 'Could not clear the cache.');
          }
        });
      }

    }
  });

  _exports.default = _default;
});