define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/gabbys-website-extra-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0d4qfZt5",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Extra Media\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@prefix\",\"@label\",\"@value\",\"@required\"],[\"#\",\"Anchor ID\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"anchor_id\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"title\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Custom CTA (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"custom_cta\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Custom CTA Link (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"custom_cta_link\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@onItemsUpdated\"],[[27,[26,1,\"AppendSingleId\"],[]],[27,[26,0,\"AppendSingleId\"],[\"data\",\"items\"]],\"resource\",[31,595,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,603,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"items\"]]],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"resourceFormOptions\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/gabbys-website-extra-media.hbs"
    }
  });

  _exports.default = _default;
});