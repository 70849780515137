define("universal-cms/controllers/gabbys-website/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dreamworks: Ember.inject.service(),
    queryParams: ['lang', 'tab'],
    isLoadingLayout: false,
    loadLayoutError: null,
    tab: 'header',
    navigationLocations: [{
      key: 'header',
      label: 'Header'
    }, {
      key: 'footer_main',
      label: 'Footer (Main)'
    }, {
      key: 'footer_copyright',
      label: 'Footer (Copyright)'
    }],
    actions: {
      updateNavigationItems(locationKey, updatedItems) {
        this.set(`model.navigationItems.${locationKey}`, updatedItems);
      },

      async saveNavigation() {
        try {
          const navigationItemId = this.model.navigationItem.id;
          const formattedNavigationItems = this.formatNavigationItems(this.model.navigationItems); // console.log('formattedNavigationItems', JSON.stringify(formattedNavigationItems, null, 2));

          const response = await this.dreamworks.updateOne('gabbys-website-navigation-items', navigationItemId, {
            items: formattedNavigationItems
          });
          this.notificationService.show('Navigation Saved', 'Navigation saved successfully.');
        } catch (error) {
          console.error('Error saving navigation:', error);
          this.notificationService.show('Error Saving Navigation', 'An error occurred while saving the navigation.', 'error');
        }
      }

    },

    formatNavigationItems(navigationItems) {
      const formattedItems = {};
      Object.keys(navigationItems).forEach(locationKey => {
        formattedItems[locationKey] = navigationItems[locationKey].map(item => {
          switch (item.icon) {
            case '/svg/character-1.svg':
              item.iconWrapperPosition = 'first';
              item.iconPosition = 'first';
              item.mobileIcon = '/svg/mobile-character-1.svg';
              break;

            case '/svg/character-2.svg':
              item.iconWrapperPosition = 'second';
              item.iconPosition = 'second';
              item.mobileIcon = '/svg/mobile-character-2.svg';
              break;

            case '/svg/character-music.svg':
              item.iconWrapperPosition = 'third';
              item.iconPosition = 'third';
              item.mobileIcon = '/svg/mobile-icon-music.svg';
              break;

            case '/svg/character-fun.svg':
              item.iconWrapperPosition = 'fourth';
              item.iconPosition = 'fourth';
              item.mobileIcon = '/svg/mobile-icon-fun.svg';
              break;

            case '/svg/character-events.svg':
              item.iconWrapperPosition = 'fifth';
              item.iconPosition = 'fifth';
              item.mobileIcon = '/svg/mobile-icon-events.svg';
              break;

            case '/svg/character-grownups.svg':
              item.iconWrapperPosition = 'sixth';
              item.iconPosition = 'sixth';
              item.mobileIcon = '/svg/mobile-icon-grownups.svg';
              break;

            default:
              item.iconWrapperPosition = '';
              item.iconPosition = '';
              item.mobileIcon = '';
              break;
          }

          return item;
        });
      });
      return formattedItems;
    }

  });

  _exports.default = _default;
});